<template>
  <b-card-code>
    <div v-if="$can('take','Payment')" class="custom-search d-flex">
        <b-button variant="outline-primary">
          <router-link :to="{name:'take-student-payment'}">{{ $t('Take Payment') }}</router-link>
        </b-button>
    </div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>
    <div class="custom-search d-flex justify-content-start">
      <vue-excel-xlsx
          :data="getData"
          :columns="columns10"
          :filename="'filename'"
          :sheetname="'subjects'"
      >
        <b>Excel</b>
      </vue-excel-xlsx>
      <vue-json-to-csv :json-data="getData"
                       :csv-title="'My_CSV'"
      >
        <button>
          <b>CSV</b>
        </button>
      </vue-json-to-csv>
      <button @click="pdfGenerate()">
        <b>PDF</b>
      </button>
    </div> 

    <!-- table -->
    <vue-good-table
        style="white-space: nowrap;text-align: center;vertical-align : middle;"
        :columns="columns"
        :rows="student_payment_list"
        :rtl="direction"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
          slot="table-row"
          slot-scope="props"
      >
        <!-- Column: index -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.originalIndex+1 }}
        </span>
        <span v-else-if="props.column.field === 'classes_id'">
          {{ classByID(props.row.classes_id) }}
        </span>

         <span v-else-if="props.column.field === 'month_year'">
          {{ getMonthName(props.row.month_year) }}
        </span>

        <span v-else-if="props.column.field === 'student_fee_type_id'">
          {{ studentFeeTypeByID(props.row.student_fee_type_id) }}
        </span>
        <span v-else-if="props.column.field === 'created_at'">
          {{ moment(props.row.created_at).format('DD-MM-yyyy') }}
          <!-- {{ getConvertedDate(props.row.created_at) }} -->
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <template >
              <b-button v-if="$can('remove','Payment')" variant="outline-danger" @click="remove(props.row.id,props.row.originalIndex)">
                <feather-icon icon="TrashIcon" class="mr-50"/>
              </b-button>
              </template>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['10','15','20','50']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
                v-model="currentPage"
                :total-rows="totalRecords"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BButton, BPagination, BFormGroup, BFormInput, BFormSelect,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
import {mapGetters} from "vuex";
import moment from "moment";
import VueExcelXlsx from "vue-excel-xlsx";
import VueJsonToCsv from 'vue-json-to-csv'
import Vue from "vue";
import download from "downloadjs";
Vue.use(VueExcelXlsx);
export default {
  name:'StudentPayment',
  components: {
    BCardCode,
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton, VueJsonToCsv
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      moment,
      student_payment_list:[],
      pageLength:20,
      totalRecords: 0,
      currentPage:1,
      nextPage: 1,
      dir: false,
      columns: [
        {
          label: 'SL',
          field: 'id',
        },
        {
          label: 'Cadet No',
          field: 'student.cadet_no',
        },
        {
          label: 'Name',
          field: 'student.nick_name',
        },
        {
          label: 'Class',
          field: 'classes_id',
        },
        {
          label: 'Student fee type',
          field: 'student_fee_type_id',
        },
        {
          label: 'Payment Month',
          field: 'month_year',
        },
        {
          label: 'Fee amount',
          field: 'fee_amount',
        },
        {
          label: 'Late fee amount',
          field: 'late_fee_amount',
        },
        {
          label: 'Total Amount',
          field: 'total_amount',
        },
        {
          label: 'Payment Date',
          field: 'created_at',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      searchTerm: '',
      columns10: [
        {
          label: 'Cadet No.',
          field: 'StudentId',
        },
        {
          label: 'Class',
          field: 'Class',
        },
        {
          label: 'Student Fee Type',
          field: 'StudentFeeType',
        },
        {
          label: 'Payment Month',
          field: 'PaymentMonth',
        },
        {
          label: 'Fee Amount',
          field: 'FeeAmount',
        },
        {
          label: 'Late Fee Amount',
          field: 'LateFeeAmount',
        },
        {
          label: 'Total Amount',
          field: 'TotalAmount',
        },
        {
          label: 'Payment Date',
          field: 'PaymentDate',
        }
      ],
    }
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.nextPage = value;
        this.getPaymentList();
      },
    },
  },
  methods:{
    async getPaymentList(){
        await apiCall.get('/get/student/payments?per_page='+this.pageLength +'&page='+ this.nextPage).then((response)=>{
          this.student_payment_list=response.data.data;
          this.totalRecords = response.data.total;
        }).catch(()=>{
          this.student_payment_list=[];
        })
      },

      getConvertedDate(dateValue){
      
        const date = new Date(dateValue);
        const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
       return formattedDate;
      },

     getMonthName(month_id){
      var month_name = '';
      if(parseInt(month_id) === 1){
        month_name = 'January';
      }
      else if(parseInt(month_id) === 2){
        month_name = 'February';
      }
      else if(parseInt(month_id) === 3){
        month_name = 'March';
      }
      else if(parseInt(month_id) === 4){
        month_name = 'April';
      }
      else if(parseInt(month_id) === 5){
        month_name = 'May';
      }
      else if(parseInt(month_id) === 6){
        month_name = 'June';
      }
      else if(parseInt(month_id) === 7){
        month_name = 'July';
      }
      else if(parseInt(month_id) === 8){
        month_name = 'August';
      }
      else if(parseInt(month_id) === 9){
        month_name = 'September';
      }
      else if(parseInt(month_id) === 10){
        month_name = 'October';
      }
      else if(parseInt(month_id) === 11){
        month_name = 'November';
      }
      else if(parseInt(month_id) === 12){
        month_name = 'December';
      }

      return month_name;
    },
    remove(id,index){
        this.$swal({
          title: 'Are you sure?',
          text: "You want to delete this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            apiCall.delete(`/student/payment/information/remove${id}`).then((response)=>{
              this.$toaster.success(response.data.message);
              this.$store.commit('REMOVE_STUDENT_PAYMENT',index);
            }).catch((error)=>{
              this.$toaster.error(error.response.data.message);
            });
          }else if (result.dismiss === 'cancel') {
            this.$swal({
              title: 'Cancelled',
              text: 'Your date is safe :)',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        })
    },
    pdfGenerate() {
      let title = "Student Payment";
      let clm = ['StudentId', 'Class','StudentFeeType', 'PaymentMonth', 'FeeAmount', 'LateFeeAmount', 'TotalAmount', 'PaymentDate' ];
      let data = new FormData();
      data.append('title', title);
      data.append('columns', JSON.stringify(clm));
      data.append('data', JSON.stringify(this.getData));
      apiCall.post('/get/common/table/export/pdf', data, {responseType: 'blob'}).then((response) => {
        const content = response.headers['content-type'];
        download(response.data, 'student_payments.pdf', content)
      }).catch((error) => {
        this.$toaster.error(error.response.data.message);
      });
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    ...mapGetters(['academic_years','academic_yearByID','student_fee_types', 'cadetIdByID',
      'versions','versionByID','studentFeeTypeByID',
      'classes','classByID','student_groups','student_groupByID']),
      getData() {
      return this.student_payment_list.map(item => ({
        StudentId: item.student ? item.student.cadet_no : '',
        Class: item.classes_id ? this.classByID(item.classes_id) : '',
        StudentFeeType: item.student_fee_type_id ? this.studentFeeTypeByID(item.student_fee_type_id) : '',
        PaymentMonth: item.month_year,
        FeeAmount: item.fee_amount,
        LateFeeAmount: item.late_fee_amount,
        TotalAmount: item.total_amount,
        PaymentDate: item.created_at,
      }));
    }
  },
  created() {
    //this.$store.dispatch('GET_ALL_STUDENT_PAYMENT');
    this.getPaymentList();
    if(this.academic_years.length <1) this.$store.dispatch('GET_ALL_ACADEMIC_YEAR');
    if(this.versions.length <1) this.$store.dispatch('GET_ALL_VERSION');
    if(this.classes.length <1) this.$store.dispatch('GET_ALL_CLASSES');
    if(this.student_groups.length <1) this.$store.dispatch('GET_ALL_STUDENT_GROUP');
    if(this.student_fee_types.length <1) this.$store.dispatch('GET_ALL_STUDENT_FEE_TYPE');
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>